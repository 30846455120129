<template>
  <div>
    <ul>
      <li class="wrap">
        <p class="title">项目名称</p>
        <p class="content">{{ detailsObj.projectName || "--" }}</p>
      </li>
      <li class="wrap">
        <p class="title">预定人</p>
        <p class="content">{{ detailsObj.userName || "--" }}</p>
      </li>
      <li class="wrap">
        <p class="title">手机号码</p>
        <p class="content">{{ detailsObj.userPhone || "--" }}</p>
      </li>
      <li class="wrap">
        <p class="title">预定日期</p>
        <p class="content">{{ detailsObj.destinedDate || "--" }}</p>
      </li>
      <li class="wrap">
        <p class="title">预定时段</p>
        <p class="content" v-for="item in detailsObj.goodsList" :key="item.id">
          <span>{{ " " + item.goodsName || "--" }}</span>
          <span v-for="(i, index) in item.goodsSlotList" :key="index"
            >{{ " " + i.destinedSlot
            }}{{ getSemicolon(item.goodsSlotList, index) }}</span
          >
        </p>
      </li>
      <li class="wrap">
        <p class="title">场地名称</p>
        <p
          class="content"
          v-for="(item, index) in detailsObj.goodsList"
          :key="index"
        >
          {{ item.goodsName || "--" }}
        </p>
      </li>
      <li class="wrap">
        <p class="title">费用</p>
        <p class="content">
          {{
            detailsObj.totalMoney || detailsObj.totalMoney === 0
              ? detailsObj.totalMoney
              : "--"
          }}元
        </p>
      </li>
      <li class="wrap">
        <p class="title">月结金额</p>
        <p class="content">
          {{
            detailsObj.monthPayMoney || detailsObj.monthPayMoney === 0
              ? detailsObj.monthPayMoney
              : "--"
          }}元
        </p>
      </li>
      <li class="wrap">
        <p class="title">月结公司</p>
        <p class="content">{{ detailsObj.monthPayCompany || "--" }}</p>
      </li>
      <li class="wrap">
        <p class="title">状态</p>
        <p class="content">{{ detailsObj.destinedStatusStr || "--" }}</p>
      </li>
      <template v-if="detailsObj.destinedRoad === 'wx'">
        <li class="wrap">
          <p class="title">微信支付交易单号</p>
          <p class="content">{{ detailsObj.transactionId || "--" }}</p>
        </li>
        <li class="wrap">
          <p class="title">微信支付服务单号</p>
          <p class="content">{{ detailsObj.destinedCode || "--" }}</p>
        </li>
        <li class="wrap">
          <p class="title">微信支付时间</p>
          <p class="content">{{ detailsObj.payDate || "--" }}</p>
        </li>
      </template>
      <li class="wrap">
        <p class="title">是否开票</p>
        <p class="content">
          <el-switch v-model="value" active-color="#13ce66" disabled>
          </el-switch>
        </p>
      </li>
      <li class="wrap">
        <p class="title">发票编号</p>
        <p class="content">{{ detailsObj.invoiceBillNo || "--" }}</p>
      </li>
      <li class="wrap">
        <p class="title">开票时间</p>
        <p class="content">
          {{
            detailsObj.invoiceTime | dateFormat("YYYY-MM-DD HH:mm:ss") || "--"
          }}
        </p>
      </li>
      <li class="wrap">
        <p class="title">预定操作人</p>
        <p class="content">{{ detailsObj.createdName || "--" }}</p>
      </li>
      <template v-if="detailsObj.destinedStatusStr === '已取消'">
        <li class="wrap">
          <p class="title">取消预定操作人</p>
          <p class="content">{{ detailsObj.closeUserName || "--" }}</p>
        </li>
        <li class="wrap">
          <p class="title">取消预定时间</p>
          <p class="content">{{ detailsObj.closeDate || "--" }}</p>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import { dateFormat } from "@/filters/index";
export default {
  props: {
    detailsObj: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    "detailsObj.invoiceStatus"(newVal, oldVal) {
      console.log(newVal);
      if (newVal === "SUCCESS") {
        this.value = true;
      } else {
        this.value = false;
      }
    },
  },
  created() {
    this.isNoinvoiceStatus();
  },
  methods: {
    isNoinvoiceStatus() {
      this.detailsObj.invoiceStatus === "SUCCESS"
        ? (this.value = true)
        : (this.value = false);
    },
    getSemicolon(list, index) {
      if (list.length - 1 > index) {
        return ";";
      }
      console.log(this.detailsObj, "detailsObjdetailsObj");
    },
  },
};
</script>
<style lang="less" scoped>
.wrap:first-child {
  margin-top: 0;
}

.wrap {
  margin-top: 20px;

  .title {
    font-size: 12px;
    margin: 4px 0;
    margin-bottom: 10px;
    color: darkgray;
  }

  .content {
    font-size: 14px;
    margin-bottom: 24px;
  }
}
</style>